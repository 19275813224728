import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton, MatIconButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from '@core/notification/notification.service';
import { QartNotification } from '@core/notification/qart-notification.types';
import { RouterLink } from '@angular/router';
import { NgClass, NgTemplateOutlet, DatePipe } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIcon } from '@angular/material/icon';
import { environment } from '@env/environment';
import { cloneDeep } from 'lodash';

/**
 * Component that displays a list of notifications in a panel that can be opened and closed.
 */
@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        TranslocoModule,
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        DatePipe
    ]
})
export class NotificationsComponent implements OnInit, OnDestroy {
    /**
     * Reference to the button that triggers the notifications panel.
     */
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;

    /**
     * Reference to the template that contains the notifications panel.
     */
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    /**
     * Array of notifications to display in the panel.
     */
    notifications: QartNotification[];

    /**
     * Template data.
     */
    tpl: {
        environment: any,
        showNotificationDot: boolean
    } = {
        environment: environment,
        showNotificationDot: false
    }

    /** 
     * Overlay reference to the notifications panel.
     */
    private _overlayRef: OverlayRef;

    /**
     * Subject that emits when the component has been destroyed.
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     *
     * @param _changeDetectorRef Reference to the component's change detector.
     * @param _notificationService Reference to the NotificationService.
     * @param _overlay Reference to the Overlay service.
     * @param _viewContainerRef Reference to the component's ViewContainerRef.
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationService: NotificationService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initializes the component and subscribes to notification changes.
     * @returns void
     */
    ngOnInit(): void {
        // Subscribe to notification changes
        this._notificationService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: QartNotification[]) => {
                if (this.notifications) {
                    // Show the animation dot
                    setTimeout(() => {
                        this.tpl.showNotificationDot = false;
                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    }, 3000);
                    this.tpl.showNotificationDot = true;
                }
                // Load the notifications
                this.notifications = cloneDeep(notifications);
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Unsubscribes from all subscriptions and disposes the overlay.
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Opens the notifications panel.
     * If the notifications panel or its origin is not defined, the function returns without doing anything.
     * If the overlay does not exist, it creates it.
     * Attaches the portal to the overlay.
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Detaches the overlay reference to close the notifications panel.
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Returns a unique identifier for each item in the notifications array.
     * If an item has an `id` property, it is used as the identifier.
     * Otherwise, the index of the item in the array is used.
     * 
     * @param index The index of the item in the array.
     * @param item The item in the array.
     * @returns The unique identifier for the item.
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * Tests the notification service.
     */
    testNotification(): void {
        this._notificationService.test();
    }

}
