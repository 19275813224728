<!-- Notifications toggle -->
<button
  mat-icon-button
  (click)="openPanel()"
  #notificationsOrigin
  class="relative">
  @if (tpl.showNotificationDot) {
    <mat-icon
      color="warn"
      [svgIcon]="'heroicons_solid:bell'">
    </mat-icon>
  } @else {
    <mat-icon
      [svgIcon]="'heroicons_outline:bell'">
    </mat-icon>
  }
  <span 
    class="absolute top-1 right-1 w-2 h-2 rounded-full bg-red-500 animate-app-dot-loader-blink-hard"
    [ngClass]="{
      'hidden': !tpl.showNotificationDot
    }">
  </span>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

  <div *transloco="let t; read: 'pages.shared.notifications'"
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

    <!-- Header -->
    <div class="flex shrink-0 items-center py-4 pr-4 px-6 bg-primary text-on-primary">
      <div class="sm:hidden -ml-1 mr-3">
        <button
          mat-icon-button
          (click)="closePanel()">
          <mat-icon
            class="icon-size-5 text-current"
          [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10">{{ t('title') }}</div>
      @if (tpl.environment.name !== 'prod') {
        <div 
          class="text-lg font-medium leading-10 cursor-pointer text-warn"
          (click)="testNotification()">(test)</div>
      }
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
      <!-- Notifications -->
      @for (notification of notifications; track trackByFn($index, notification)) {
        <div
          class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
          [ngClass]="{'unread': !notification.read}">
          <!-- Notification with a link -->
          @if (notification.link) {
            <!-- Normal links -->
            @if (!notification.useRouter) {
              <a
                class="flex flex-auto py-5 px-6 cursor-pointer"
                [href]="notification.link">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            }
            <!-- Router links -->
            @else {
              <a
                class="flex flex-auto py-5 px-6 cursor-pointer"
                [routerLink]="notification.link">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            }
          }
          <!-- Notification without a link -->
          @else {
            <div class="flex flex-auto py-5 px-6">
              <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </div>
          }
        </div>
        <!-- Notification content template -->
        <ng-template #notificationContent>
          <!-- Icon -->
          @if (notification.icon && !notification.image) {
            <div class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
              <mat-icon
                class="icon-size-5"
                [svgIcon]="notification.icon">
              </mat-icon>
            </div>
          }
          <!-- Image -->
          @if (notification.image) {
            <img
              class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
              [src]="notification.image"
              [alt]="'Notification image'">
          }
          <!-- Title, message & time -->
          <div class="flex flex-col flex-auto">
            @if (notification.title) {
              <div
                class="font-semibold line-clamp-1"
              [innerHTML]="notification.title"></div>
            }
            @if (notification.message) {
              <div
                class="line-clamp-2"
              [innerHTML]="notification.message"></div>
            }
            <div class="mt-2 text-sm leading-none text-secondary">
              {{ notification.createdAt | date:'MMM dd, h:mm a' }}
            </div>
          </div>
        </ng-template>
      }

      <!-- No notifications -->
      @if (!notifications || !notifications.length) {
        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
          <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
            <mat-icon
              class="text-primary-500-700"
            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
          </div>
          <div class="mt-5 text-2xl font-semibold tracking-tight">{{ t('no-notifications-title') }}</div>
          <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ t('no-notifications-subtitle') }}</div>
        </div>
      }

    </div>

  </div>

</ng-template>
