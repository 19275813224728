
<ng-container *transloco="let t; read: 'pages.shared.user'">

  <!-- Button -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative flex flex-col items-center justify-center">
      @if (showAvatar && user?.photoUrl) {
        <img
          class="w-7 h-7 rounded-full"
          [src]="user?.photoUrl">
      }
      @else {
        <mat-icon
        [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      }
      <span
        class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
        [ngClass]="{'mr-px mb-px': !showAvatar || !user?.photoUrl}"
      ></span>
    </span>
  </button>

  <mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span>{{ t('signed-in-as') }}</span>
        <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item
      [routerLink]="['/settings/security']">
      <mat-icon [svgIcon]="'heroicons_outline:lock-closed'"></mat-icon>
      <span>{{ t('security') }}</span>
    </button>
    <!--
    <button mat-menu-item>
      <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
      <span>Settings</span>
    </button>
    -->
    <button
      mat-menu-item
      (click)="signOut()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
      <span>{{ t('sign-out') }}</span>
    </button>
  </mat-menu>
</ng-container>
